import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RequestData } from '../modal/base-save-update-modal';
import { AuthUtilsService } from '../util/auth-utils.service';
import { IBaseService } from './ibase-service';
import { ResponseData } from '../modal/base-save-update-response';
import { PaginationData } from '../modal/pagination-data';
import { GetAllRequest } from '../modal/get-all-request';
import { OrderingData } from '../modal/ordering-data';

export abstract class BaseService<T> implements IBaseService<T> {
  constructor(public http: HttpClient, public authService: AuthUtilsService) {}
  save(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/save", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save', reqObj.data);
    // }
  }

  saveV2(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/save", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save/v2', reqObj.data);
    // }
  }

  saveV3(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/save", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save/v3', reqObj.data);
    // }
  }

  saveWithImage(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    //uploadFormData.append("entityDTOString","" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save', uploadFormData);
  }

  saveWithImageV2(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    //uploadFormData.append("entityDTOString","" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save/v2', uploadFormData);
  }

  saveWithImageV3(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    //uploadFormData.append("entityDTOString","" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/save/v3', uploadFormData);
  }

  updateWithImage(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    // uploadFormData.append("uploadingFiles", file, file.name);
    // uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/update', uploadFormData);
  }

  updateWithImageV2(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    // uploadFormData.append("uploadingFiles", file, file.name);
    // uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(
      environment.SERVER_URL + this.getModuleName() + '/update/v2',
      uploadFormData
    );
  }

  updateWithImageV3(modal: T, files: Array<File>): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    const uploadFormData = new FormData();
    files.forEach((file) => {
      uploadFormData.append('file', file, file.name);
    });
    uploadFormData.append('entityDTOString', '' + JSON.stringify(reqObj.data));
    // uploadFormData.append("uploadingFiles", file, file.name);
    // uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
    return this.http.post<ResponseData<T>>(
      environment.SERVER_URL + this.getModuleName() + '/update/v3',
      uploadFormData
    );
  }

  updateImage(
    foreinKey: number,
    moduleName: string,
    file: File
  ): Observable<ResponseData<{ foreinKey: number; moduleName: string; imagePath: string; id: number }>> {
    let reqObj: RequestData<{ foreinKey: number; moduleName: string }> = new RequestData<{
      foreinKey: number;
      moduleName: string;
    }>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = { foreinKey: foreinKey, moduleName: moduleName };
    const uploadFormData = new FormData();
    uploadFormData.append('uploadingFiles', file, file.name);
    uploadFormData.append('bodyData', '' + JSON.stringify(reqObj));
    return this.http.post<ResponseData<{ foreinKey: number; moduleName: string; imagePath: string; id: number }>>(
      environment.SERVER_URL + this.getModuleName() + '/updateWithFile',
      uploadFormData
    );
  }

  saveImage(
    foreinKey: number,
    moduleName: string,
    file: File
  ): Observable<ResponseData<{ foreinKey: number; moduleName: string; imagePath: string; id: number }>> {
    let reqObj: RequestData<{ foreinKey: number; moduleName: string }> = new RequestData<{
      foreinKey: number;
      moduleName: string;
    }>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = { foreinKey: foreinKey, moduleName: moduleName };
    const uploadFormData = new FormData();
    uploadFormData.append('uploadingFiles', file, file.name);
    uploadFormData.append('bodyData', '' + JSON.stringify(reqObj));
    return this.http.post<ResponseData<{ foreinKey: number; moduleName: string; imagePath: string; id: number }>>(
      environment.SERVER_URL + 'filesData/saveWithFile',
      uploadFormData
    );
  }

  update(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/update", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/update', reqObj.data);
    // }
  }

  updateV2(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/update", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/update/v2', reqObj.data);
    // }
  }

  updateV3(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/update", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/update/v3', reqObj.data);
    // }
  }

  saveOrUpdate(modal: T): Observable<ResponseData<T>> {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/saveOrUpdate', modal);
  }
  saveOrUpdateAll(modal: Array<T>): Observable<ResponseData<T>> {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/saveOrUpdateAll', modal);
  }
  delete(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/delete', reqObj.data);
    // }
  }
  deleteV2(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/delete/v2', reqObj.data);
    // }
  }
  getById(id: number): Observable<ResponseData<T>> {
    // if (environment.moduleName == 'textile') {
    //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/" + id);
    // } else {
    return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/' + id);
    // }
  }
  getByIdV2(id: number): Observable<ResponseData<T>> {
    return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/v2/' + id);
  }
  getByIdV3(id: number): Observable<ResponseData<T>> {
    return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/v3/' + id);
  }
  getAll(paginationData: PaginationData, orderingData: OrderingData): Observable<ResponseData<T>> {
    let requestData: GetAllRequest = new GetAllRequest();
    requestData.data = { paginationData: paginationData, orderingData: orderingData };
    // if (environment.moduleName == 'textile') {
    //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName());
    // } else {
    return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + '/getAllByPartner');
    // }
  }

  getAllV2(paginationData: PaginationData, orderingData: OrderingData): Observable<ResponseData<T>> {
    let requestData: GetAllRequest = new GetAllRequest();
    requestData.data = { paginationData: paginationData, orderingData: orderingData };
    // if (environment.moduleName == 'textile') {
    //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName());
    // } else {
    return this.http.get<ResponseData<T>>(
      environment.SERVER_URL + 'chemical/storeManagement/allocate/getAllByPartner/v2'
    );
    // }
  }

  getAllInwardStock(paginationData: PaginationData, orderingData: OrderingData): Observable<ResponseData<T>> {
    let requestData: GetAllRequest = new GetAllRequest();
    requestData.data = { paginationData: paginationData, orderingData: orderingData };
    // if (environment.moduleName == 'textile') {
    //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName());
    // } else {
    let cbl = {};
    return this.http.post<ResponseData<T>>(environment.SERVER_URL + 'chemical/itemStock/getStock/v2', cbl);
    // }
  }

  getRequestToken(): string {
    return 'xyz1234';
  }
  abstract validate(): boolean;
  abstract getModuleName(): string;
  // abstract  getId(): number;
}
